<template>
  <v-container fluid>
    <!-- Divider -->
    <v-row class='mt-10'>
      <v-divider />
    </v-row>
    <v-row>
      <v-col>
        <v-img :src='require(`@/assets/home_page/special_event/product.jpg`)' />
      </v-col>
    </v-row>
    <v-row class='mt-10 d-flex justify-center'>
      <p class='text-body-1 font-weight-bold'>
        Les frais de livraisons vous sont offerts à partir de {{ 59.90 | currencyDefault }}
      </p>
    </v-row>
    <!-- Amount summary -->
    <v-row class='mt-10'>
      <v-col cols='8'
             offset='2'>
        <v-row>
          Produits
          <v-spacer />
          {{ cart.attributes.total_products | currencyDefault }}
        </v-row>
        <v-row>
          Livraison
          <span v-if='cart.attributes.total_shippings === 0'>&nbsp;offerte!</span>
          <v-spacer />
          {{ cart.attributes.total_shippings | currencyDefault }}
        </v-row>
        <v-row>
          Total
          <v-spacer />
          <span class='font-weight-bold green--text text-body-1'>
            {{ cart.attributes.total_amount | currencyDefault }}
          </span>
        </v-row>
      </v-col>
    </v-row>
    <!-- Inputs -->
    <v-row class='mt-10 d-flex align-center'>
      <v-col cols='4' />
      <v-col cols='4'
             v-if='!(cart.attributes.promo || {}).code'>
        <v-text-field label='Saisir un code promo'
                      v-model='promoCode'
                      @change='applyPromo' />
      </v-col>
      <v-col cols='4'
             v-if='!!(cart.attributes.promo || {}).code'>
        Code promo utilisé : {{ cart.attributes.promo.code }}
        <span v-if='cart.attributes.promo.promo_type === `percentage`'
              class='green--text'>
          - {{ cart.attributes.promo.value }} %
        </span>
        <span v-else
              class='green--text'>
          - {{ cart.attributes.promo.value | currencyDefault }}
        </span>
      </v-col>

      <!-- submit btn -->
      <v-col cols='10'
             offset='1'
             class='d-flex justify-center'>
        <v-btn class='custom-btn ubn-bg-color white--text'
               elevation='1'
               :disabled='!isSubmittable'
               @click='submit'
               text>
          Passer la commande
        </v-btn>
      </v-col>
    </v-row>
    <!-- Payment -->
    <v-row justify='center'>
      <v-dialog v-model='dialog'
                fullscreen
                hide-overlay
                transition='dialog-bottom-transition'>
        <v-card>
          <v-toolbar dark
                     color='primary'>
            <v-toolbar-title>Paiement</v-toolbar-title>
          </v-toolbar>

          <v-card-text class='d-flex align-center justify-center'
                       style='height: 80vh;'>
            <v-progress-circular indeterminate
                                 :size='70'
                                 :width='7'
                                 :active='loading' />

            <stripe-checkout ref='checkoutRef'
                             mode='payment'
                             :pk='publishableKey'
                             :session-id='session'
                             @loading='v => this.loading = v' />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Validate cart to order (no payment) -->
    <v-row justify='center'>
      <v-dialog v-model='dialogValidate'
                fullscreen
                hide-overlay
                transition='dialog-bottom-transition'>
        <v-card>
          <v-toolbar dark
                     color='primary'>
            <v-toolbar-title>Validation du panier</v-toolbar-title>
          </v-toolbar>

          <v-card-text class='d-flex align-center justify-center'
                       style='height: 80vh;'>
            <v-card>
              <v-card-title class='teal lighten-3 white--text font-weight-bold'>
                Commande validée
              </v-card-title>

              <v-card-text class='text-center text-body-1 mt-5'>
                Votre commande est validée et envoyée à nos graphistes.
              </v-card-text>

              <v-card-actions>
                <v-container>
                  <v-row class='ma-4 text-caption'>
                    Vous allez être redirigé automatiquement dans quelques secondes.
                  </v-row>

                  <v-row class='ma-4'>
                    Si rien ne se passe, cliquez&nbsp;
                    <router-link to='/carts'>
                      ici
                    </router-link>
                    .
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import { StripeCheckout } from '@vue-stripe/vue-stripe';

  export default {
    name: 'OrderValidation',
    components: { StripeCheckout },
    props: {
      cart: { type: Object, default: () => {} }
    },
    data: () => ({
      dialog: false,
      dialogValidate: false,
      loading: false,
      publishableKey: '',
      session: '',
      promoCode: ''
    }),
    computed: {
      isSubmittable() {
        const attr = this.cart.attributes;

        return attr.firstname && attr.lastname && attr.firstname && attr.email && attr.phone && attr.address && attr.zipcode && attr.city;
      }
    },
    methods: {
      submit() {
        if(this.cart.attributes.payment_type === 'now') {
          this.initializePayment();
        } else if(this.cart.attributes.payment_type === 'later') {
          this.CartToOrder();
        }
      },
      initializePayment() {
        this.dialog = !this.dialog;
        this.loading = true;
        this.publishableKey = process.env.VUE_APP_STRIPE_PK;

        this.axios.post(`v1/checkout/carts/${localStorage.getItem('cart_secure_session')}`)
                  .then(response => {
                    this.session = response.data.id;
                    this.$refs.checkoutRef.redirectToCheckout();
                  });
      },
      CartToOrder() {
        this.dialogValidate = true;

        this.axios.put(`v1/checkout/carts/${localStorage.getItem('cart_secure_session')}`)
                  .then(() => {
                    localStorage.removeItem('cart_secure_session');
                    localStorage.removeItem('cart_items');

                    setTimeout(() => { window.location = '/'; }, 4000);
                  });
      },
      applyPromo() { this.$root.$emit('applyPromo', this.promoCode) }
    }
  }
</script>
