<template>
  <v-dialog transition='dialog-bottom-transition'
            v-model='dialog'
            max-width='1300'>
    <template>
      <v-card>
        <v-toolbar color='grey lighten-3'
                   class='d-flex justify-end'
                   dark>
          <v-btn icon
                 @click='close'>
            <v-icon class='grey--text text--darken-1'>
              mdi-close-circle-outline
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class='pt-5'>
          <v-img :src='require(`@/assets/home_page/special_event/default.jpg`)'
                 class='d-none d-md-block' />

          <v-img :src='require(`@/assets/home_page/special_event/mobile.jpg`)'
                 class='d-md-none' />
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SpecialEvent',
    data: () => ({
      dialog: false
    }),
    mounted() { this.open() },
    methods: {
      close() {
        this.dialog = false;
      },
      open() {
        const date    = this.$moment(localStorage.getItem('special_event_warning_dismissed_at')).add(10, 'H');
        const now     = this.$moment();
        const display = process.env.VUE_APP_SPECIAL_EVENT === 'true'

        if(display && (date.isBefore(now) || !date.isValid())) {
          this.dialog = true;
          localStorage.setItem('special_event_warning_dismissed_at', this.$moment().toDate());
        }
      }
    }
  }
</script>
