<template>
  <v-container fluid
               class='mt-10 mb-10 pa-0'>
    <StandardHome :calendar='calendar'
                  :carousel-images='carouselImages' />

    <MobileHome :calendar='calendar'
                :carousel-images='carouselImages' />

    <Holidays />
    <SpecialEvent />
  </v-container>
</template>

<script>
  import StandardHome from './StandardHome';
  import MobileHome   from './MobileHome';
  import Holidays     from './Holidays';
  import SpecialEvent from './SpecialEvent.vue';

  export default {
    name: 'HomePage',
    components: { MobileHome, StandardHome, Holidays, SpecialEvent },
    data: () => ({
      calendar: {
        id: null,
        lazy_images_urls: [],
        images_urls: [],
        length: 0
      },
      carouselImages: [],
      selectedVariant: {}
    }),
    mounted() {
      this.fetchCalendar();
      this.fetchCarouselImages();
    },
    methods: {
      fetchCalendar() {
        this.axios.get(`/v1/products/${process.env.VUE_APP_CALENDAR_ID}`)
                  .then(response => {
                    // this.product = response.data.data.attributes;
                    // this.selectedVariant = this.product.variants[0].data.attributes;
                    const product = response.data.data.attributes;

                    this.calendar = {
                      id: process.env.VUE_APP_CALENDAR_ID,
                      lazy_images_urls: product.variants[0].data.attributes.lazy_images_urls,
                      images_urls: product.variants[0].data.attributes.images_urls,
                      length: 2
                    }
                  });
      },
      fetchCarouselImages() {
        this.axios.get('/v1/menu_images/')
                  .then(response => this.carouselImages = response.data);
      }
    }
  }
</script>
